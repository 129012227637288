import React, {
    useEffect, useState,
  } from 'react';
  import {
    Row, Col, Form, Input, Button, Alert,
  } from 'antd';
  import 'antd/dist/antd.css';
  
  import logo from '../../assets/images/ibee-hosting-logo.png';
  import { apiURL } from '../../common/crud';
  
  const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [loginErr, setLoginErr] = useState(null);
  
    const regEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  
    const restformSuccess = (values) => {
      const token = sessionStorage.getItem('passTok');
  
      const passwordBody = {
        newpassword: values.newPassword,
      };
      console.log('Reset form values - ', values);
  
      if (token) {
        fetch(`${apiURL}/users/invite/${token}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',  },
          body: JSON.stringify(passwordBody),
        }).then((res) => {
          console.log('Update Password res - ', res);
          if (res.success === true) {
            sessionStorage.removeItem('passTok');
            window.location('/');
          }
        });
      }
    };
  
    useEffect(() => {
      console.log('token = ');
      setLoginErr(null);
      setLoading(false);
  
      return () => {
  
      };
    }, []);
  
    return (
  
      <div className="full-width login-block px-3 ">
        <div className="full-width text-start branding-logo pb-2 login-signup-header mb-2">
          <Row>
            <Col span="12">
              <img
                src={logo}
                className="logo d-inline-block"
                alt="IBEE Hosting"
              />
            </Col>
            <Col span="12" className="">
              <div className="login-signup-main-title full-width text-end">
                Reset Password
              </div>
              <div className="login-signup-short-info full-width text-end">
                Create new password.
              </div>
            </Col>
          </Row>
        </div>
  
        <div className="full-width login-form-block">
          {loginErr ? (
            <div className="full-width mb-2">
              <Alert
                message={loginErr}
                type="error"
              />
            </div>
          ) : null}
          <div className="full-width">
            <Form
              layout="vertical"
              onFinish={restformSuccess}
            >
              <Form.Item
                label="Password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: 'Please enter password',
                  },
                  () => ({
                    validator(_, value) {
                      if (!value || regEx.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Must be min. 8 characters, lower case, uppercase and special character!'));
                    },
                  }),
                ]}
              >
                <Input.Password
                  className="form-control fs-5"
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="retypePassword"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password
                  className="form-control fs-5"
                  size="large"
                  name="password"
                />
              </Form.Item>
  
              <Form.Item className="flex">
                <div className="d-flex align-items-center justify-content-start">
                  <Button
                    htmlType="submit"
                    disabled={loading}
                    className="loginBtn align-self-center btn-primary"
                    type="primary"
                    style={{ width: '220px' }}
                  >
                    Update Password
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
  
    );
  };
  
  export default ResetPassword;
  