import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  message,
  Button,
  Breadcrumb,
  Card,
  Alert,
  Checkbox,
  Table,Form
} from "antd";
import { IoMdLock, IoMdUnlock, IoMdCloudDownload } from "react-icons/io";
import { RiLockPasswordFill } from "react-icons/ri";
import {FaUserTimes} from "react-icons/fa";

// Custom imports
import "./profile.scss";
import { Context } from "../../context";
import { BREADCRUMB } from "../../reducer/types";
import { LoadPage } from "../../SharedFunctions";
import { POST , apiURL , GET , PUT } from "../../common/crud" ;


const ProfileAccount = () => {
  const { state, dispatch } = useContext(Context);
  const [form] = Form.useForm();
  const [customer , setCustomer] = useState(null);
  const [ slform , setSLForm] = useState(false);
  const [qrcode,setQrcode] = useState(null);

  const sendLogOnChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  //Log Table Content
  
  const columns = [
    {
      title: "IP Address",
      dataIndex: "originip",
      key: "originip",
    },
    {
      title: "Date",
      dataIndex: "at",
      key: "at",
    },
    {
      title: "Status",
      dataIndex: "status_code",
      key: "status_code",
    },
  ];

  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage("/", dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage("profileAccount", dispatch)}
        >
          Profile
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Account</Breadcrumb.Item>
    </Breadcrumb>
  );

  const getCustomerData = () => {
    GET(`customers/${state.userId}`, state.token).then((res) => {
      //  setLoading(false);
      
      const tableDataArray = [];
      if (res.success === true) {  
          setCustomer(res.data);
          sessionStorage.setItem("userData",JSON.stringify(res.data));
          console.log('Get res - ', res);
      } else if (res.success === false) {
        message.error('Something went wrong! Please try again!!!');
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  const editCustomer = (obj) => {
    //console.log(values);
    let cust = { ...customer ,  ...obj  };
    //setCustomer(cust);
    PUT(`customers`,cust._id,state.token,cust).then((res)=>{
        console.log(res);
        getCustomerData();
    });
    console.log(cust);
  };

  const LoginFormSuccess = (values) => {
    console.log('Login values', values);
    //setLoginErr(null);
    const bodyData = {
      email: values.username,
      password: values.password,
      client_id: 'user_portal',
    };

    let formBody = [];
    Object.keys(bodyData).map((key) => {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(bodyData[key]);
      return (
        formBody.push(`${encodedKey}=${encodedValue}`)
      );
    });
    formBody = formBody.join('&');

    console.log('Form Body - ', formBody);
    fetch(`${apiURL}/oauth2/get2fa`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: formBody,
    }).then((res) => res.json()).then((data) => {
        console.log(data);
        setQrcode(data.qrcode);
    }).catch((err) => {
      console.log('Login err - ', err);
    });
  };


  useEffect(() => {
    getCustomerData();
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Profile - <span>Account</span>
            </Col>
            <Col span="10" flex="row" align="end"></Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col className="py-1 px-2" xs={24} xxl={12}>
          {/* Account Starts */}
          <Card
            title="Account"
            extra={<Button type="default">Edit</Button>}
            className="profile-card full-width"
          >
            <ul className="list-unstyled full-width account-list-row">
              <li>
                <Row>
                  <Col span="8">Account Number:</Col>
                  <Col span="16">{ customer ? customer._id : null }</Col>
                </Row>
              </li>
              <li>
                <Row>
                  <Col span="8">Accout Name:</Col>
                  <Col span="16">
                  { customer ? customer.email : null }
                  </Col>
                </Row>
              </li>
              <li>
                <Row>
                  <Col span="8">Currency</Col>
                  <Col span="16">{ customer ? customer.currency : null }</Col>
                </Row>
              </li>
            </ul>
          </Card>
          {/* Account Ends */}
        </Col>

        <Col className="py-1 px-2" xs={24} xxl={12}>
          {/* Security Access Preferences Starts */}
          <Card
            title="Security and Access Preferences"
            extra={
              <>
              { customer && customer.enable_2fa ? <Button type="default" className="card-edit-btn" size={"small"}
              onClick={()=> setSLForm(true) }>
                Add Device
              </Button> : null  }
              <Button type="default" className="card-edit-btn" size={"small"}
              onClick={()=> {  editCustomer( { enable_2fa : customer && customer.enable_2fa ? false : true  })  }} >
                { customer ? customer.enable_2fa ? "Disable 2FA" : "Enable 2FA" : null  }
              </Button></>
            }
            className="profile-card full-width"
          >
            {slform && qrcode === null?
              <div className="full-width ">
                          <Form
                            form={form}
                            layout="vertical"
                            onFinish={LoginFormSuccess}
                          >
                            <Form.Item
                              label="Email Address"
                              name="username"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your Email Id!",
                                },
                              ]}
                            >
                              <Input className="form-control" />
                            </Form.Item>
                            <Form.Item
                              label="Password"
                              name="password"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your Password!",
                                },
                              ]}
                            >
                              <Input type="password" className="form-control" />
                            </Form.Item>
                            <Form.Item className="flex">
                              <div className="d-flex align-items-center justify-content-start">
                                <Button
                                  htmlType="submit"
                                  className="loginBtn align-self-center btn-primary"
                                  type="primary"
                                  //onClick={() => loginNow()}
                                >
                                  View QRcode
                                </Button>
                              </div>
                            </Form.Item>
                          </Form>
                        </div>
            :
            qrcode ?
            <div className="full-width">
                <img  style={{height:240,width:'auto'}} src={"data:image/png;base64,"+qrcode}  alt={"qrcode should be shown here"}></img>
            </div>:null            
            }
            <div className="full-width">
              <Alert message="Info Text" type="info" showIcon />
            </div>
            <div className="full-width my-2">
              Allow support to log into my account for 24 hours
            </div>
            <div className="full-width">
              <Button type="primary" className="btn-success" 
              onClick={()=> {  editCustomer( { support_login : customer && customer.support_login ? false : true  })  }} >
                { customer ? customer.support_login ? "Disallow" : "Allow" : null  }
              </Button>
            </div>
          </Card>
          {/* Security Access Preferences Ends */}
        </Col>

        <Col className="py-1 px-2" xs={24} xxl={12}>
          {/* Authorization History Starts */}
          <Card
            title="Authorization history"
            extra=""
            style={{ width: "100%" }}
            className="profile-card"
          >
            <div className="full-width fs-6 mb-2">
              <Checkbox onChange={() => editCustomer({ login_notify : customer && customer.login_notify ? false : true   })  } 
              checked={customer && customer.login_notify}>
                Send notification about each authorization attempt to
                { customer ? " "+customer.email : null }
              </Checkbox>
            </div>

            <div className="full-width log-table mb-2">
              <Table dataSource={ customer ? customer.log_history : [] } columns={columns} />
            </div>
          </Card>
          {/* Authorization History Ends */}
        </Col>

        <Col className="py-1 px-2" xs={24} xxl={12}>
          {/* Currnet User Starts */}
          <Card
            title="Current user"
            extra={
              <Button type="default" className="card-edit-btn">
                <RiLockPasswordFill /> Change Password
              </Button>
            }
            style={{ width: "100%" }}
            className="profile-card"
          >
            <ul className="list-unstyled full-width account-list-row">
              <li>
                <Row>
                  <Col span="8">Name:</Col>
                  <Col span="16">
                  { customer ? customer.email : null }
                  </Col>
                </Row>
              </li>
              <li>
                <Row>
                  <Col span="8">Account:</Col>
                  <Col span="16">{ customer ? customer._id + customer.email : null }</Col>
                </Row>
              </li>
              <li>
                <Row>
                  <Col span="8">Last Login:</Col>
                  <Col span="16">{ customer ? customer.last_login : null }</Col>
                </Row>
              </li>
            </ul>
            <div className="full-width mt-2">
              <Button type="default" className="btn-info me-1 text-white">
                <IoMdCloudDownload style={{ marginRight: "5px" }} /> Export
                Personal Data
              </Button>
              <Button type="primary" className="btn-danger me-1">
                <FaUserTimes style={{ marginRight: "5px" }} /> Deactivate user
              </Button>
            </div>
          </Card>
          {/* Currnet User Ends */}
        </Col>

        <Col className="py-1 px-2" xs={24} xxl={12}>
          {/* Referral Program Starts */}
          <Card
            title="Referral Program"
            extra=""
            style={{ width: "100%" }}
            className="profile-card"
          >
            <div className="full-width">
              Share a free month with a friend! Refer a friend with one of this
              links and choose how much you will split. You give your friend a
              link with a coupon for ..., and once he pays ... top of that, you
              get ... too!
            </div>
            <div className="full-width my-1">
              <Row>
                <Col span="6">
                  <Input disabled className="text-dark" value="Rs.50.00 link" />
                </Col>
                <Col span="18">
                  <Input
                    disabled
                    className="text-dark"
                    value="https://portal.ibee.in"
                  />
                </Col>
              </Row>
            </div>
            <div className="full-width my-1">
              If you’re looking for a faster reward, opt in for ... coupon: your
              friend will get ..., and once he makes a ... payment, you get your
              .... Fast reward and large reward — everything is possible with
              Servers.com referral program.
            </div>
            <div className="full-width my-1">
              <Row>
                <Col span="6">
                  <Input disabled className="text-dark" value="Rs.50.00 link" />
                </Col>
                <Col span="18">
                  <Input
                    disabled
                    className="text-dark"
                    value="https://portal.ibee.in"
                  />
                </Col>
              </Row>
            </div>
          </Card>
          {/* Referral Program Ends */}
        </Col>

        <Col className="py-1 px-2" xs={24} xxl={12}>
          {/* Push Notifications Starts */}
          <Card
            title="Push notifications"
            extra=""
            style={{ width: "100%" }}
            className="profile-card"
          >
            <div className="full-width mb-1">
              Please subscribe if you want to receive push notifications from the Servers.com Customer Portal.
            </div>
            <div className="full-width">
              <Button type="primary" className="btn-success">Subscribe</Button>
            </div>
          </Card>
          {/* Push Notifications Ends */}
        </Col>
      </Row>
    </>
  );
};

export default ProfileAccount;
