import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Breadcrumb,
  Tooltip,
  Select,
  Table,
  Tag,message
} from 'antd';
import { FaRupeeSign, FaCloudDownloadAlt } from 'react-icons/fa';
import { IoAdd, IoPencil, IoTrash, IoInformationCircle,  IoCheckmarkCircle,
  IoAlertCircle,
 } from 'react-icons/io5';


//Custom imports
import { Context } from '../../context';
import { BREADCRUMB } from '../../reducer/types';
import { LoadPage, EditForm } from '../../SharedFunctions';
import { POST , apiURL , GET , PUT  , DELETE} from "../../common/crud" ;


const Invoices = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [cust,setCust] = useState( JSON.parse(sessionStorage.getItem("userData")));
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [chasisSKU, setChasisSKU] = useState('');
  const [chasisTableData, setChasisTableData] = useState(null);
  const [ramSKU, setRamSKU] = useState(null);
  const [hddSKU, setHddSKU] = useState(null);
  const [detailObj,setDetailObj] = useState(null);

  const { state, dispatch } = useContext(Context);
  const { Option } = Select;
  const { TextArea } = Input;

  //Table Columns
  const ordersCols = [
    {
      title: 'Date',
      dataIndex: 'order_date',
      key: 'order_date',
      width: '125px',
    },
    {
      title: 'Invoices#',
      dataIndex: '_id',
      key: '_id',
      render: (e,record) => (
        <Button type="link" className="p-0" onClick={() => goToOrderDetail(record)}>
          {record._id}
        </Button>
      ),
    },
    {
      title: 'Last Invoice',
      dataIndex: 'last_invoice',
      key: 'last_invoice',
    },

    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      className: 'text-end',
      width: '150px',
      render: (text) => (
        <>
          {text !== 'NA' && text !== 'N/A' ? (
            <>
              <FaRupeeSign size="13" color="#777" />
              {text/100}
            </>
          ) : (
            { text }
          )}
        </>
      ),
    },
    {
      title: 'Order Status',
      dataIndex: 'order_status',
      key: 'order_status',
      width: '120px',
    },

    {
      title: 'Payment Status',
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: (e,record) => (
        <>
          { record.amount_due === 0 ? (
            <Tag color="green">{record.payment_status}</Tag>
          ) : record.amount_due > 0 ? (
            <>
              <Button className="order-now-btn" 
                         onClick={()=>  {
                          LoadPage("checkout", dispatch);
                          sessionStorage.setItem("checkout",JSON.stringify( { ...record , co_type : "invoice" }) );
                         } }>
                          Pay { record.amount_due/100}
                         </Button>
            </>
          ) : record.payment_status === 'payment failed' || record.payment_status === "pending" ? (
            <>
              <Tag color="orange">{record.payment_status}</Tag>
            </>
          ) : (
            ''
          )}
        </>
      ),
    },
  ];

  //Table Data
  const processorTableData = [
    {
      key: 'id1',
      date: '14 Jul, 2021',
      orders: '#INV123456',
      displayName: 'Company Nmae 1',
      customer: 'S Yugandhar',
      email: 'yugandhr.designer@gmail.com',
      phone: '+9112134456789',
      location: 'Hyderabad, India',
      category: 'Baremetal',
      productTitle: 'E5-2430 v3/DDR5/16GB/SSD/1TB',
      lastInvoice: '10 Mar 2021',
      amount: '22,500.00',
      orderStatus: 'Provisioning',
      paymentStatus: 'paid',
    },
    {
      key: 'id5',
      date: '04 Jun, 2021',
      orders: '#INV548728',
      displayName: 'Company Name 3',
      customer: 'Accenture',
      email: 'shanker.yguandhar@gmail.com',
      phone: '+9112134456789',
      location: 'USA',
      category: 'Firewall',
      productTitle: 'E5-2430 v3/DDR4/8GB/SSD/500GB',
      lastInvoice: '8 Mar 2021',
      amount: '18,500.00',
      orderStatus: 'Delivered',
      paymentStatus: 'not paid',
    },
  ];

  //Show Order Details
  const goToOrderDetail = (obj) => {
    //LoadPage('invoiceDetail', dispatch);
    console.log(obj);
      setDetailObj(obj);
  };

  //Show Customer Detail
  const goToCustomerDetail = (id) => {
    LoadPage('customerDetails', dispatch);
  };

  //Row Data on Edit
  const tableRowEdit = (key) => {
    console.log('Got Row ID - ', key);
    //EditForm(key, "addLocation", dispatch);
    setUpdateModal(true);
  };

  //Create Location SUCCESS
  const createNewLocationSuccess = () => {};

  //Create Location FAILED
  const createNewLocationFailed = () => {};

  //Common onChange
  const commonOnChange = () => {};

  //Confirm Modal Delete
  const deleteItem = (id) => {
    setDeleteModal(true);
  };

  //Row Data on Delete
  const tableRowDelete = (key) => {
    console.log('Got Row Id, DELETE - ', key);

    //For Demo only Check & Delete
    const updatedTable =
      tableData !== null && tableData.length > 1
        ? tableData.splice(key, 1)
        : [];

    setTableData(updatedTable);
  };

  //Row Selection
  const rowSelection = {
    onChange: (e) => {
      console.log('Row selection on change - ', e);
    },
  };

  //Cancel Delete Modal
  const cancelModal = () => {
    setDeleteModal(false);
  };

  const showModal = () => {
    setUpdateModal(true);
  };

  const handleCancel = () => {
    setUpdateModal(false);
  };

  //Chasis SKU on change
  const chasisSKUOnChange = (value) => {
    setChasisSKU(value);
  };

  //RAM SKU onChange
  const ramSKUOnChange = (value) => {
    setRamSKU(value);
  };

  // HDD SKu onChange
  const hddSKUOnChange = (value) => {
    setHddSKU(value);
  };

  //Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('/', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>Invoices</Breadcrumb.Item>
    </Breadcrumb>
  );

  const getInvoices = () =>
  {
      GET(`invoices/customer/${state.userId}`,state.token).then((res)=>{
          console.log(res.data);
          setTableData(res.data);
      }).catch((err)=>{
        console.log(err);
      })
  }

  
  useEffect(() => {
    //Bredcrumb
    getInvoices();
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    //set Table Data
    setTableData(processorTableData);

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
      detailObj === null ?
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Invoices
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24" className="mb-1">
          <Row gutter="30">
            <Col span="18">
              <Row gutter="30">
                <Col>
                          <Select
                            placeholder="Select Location"
                            defaultValue={'All Locations'}
                            onChange={commonOnChange}
                            style={{ width: '200px' }}
                          >
                            <Option value="All Locations">All Locations</Option>
                            <Option value="Hyderabad,India">Hyderabad, India</Option>
                            <Option value="Mumbai,India">Mumbai, India</Option>
                          </Select>
                        </Col>
                        <Col>
                          <Select
                            placeholder="Select Status"
                            defaultValue='All'
                            onChange={commonOnChange}
                            style={{ width: '200px' }}
                          >
                            <Option value="All">All Payment Status</Option>
                            <Option value="paid">paid</Option>
                            <Option value="payment failed">payment failed</Option>
                            <Option value="not paid">not paid</Option>
                          </Select>
                        </Col>
              </Row>
            </Col>
            <Col span="6" className="text-end">
              Period:&nbsp;&nbsp;
              <Select
                placeholder="Select Year"
                defaultValue={'2021'}
                onChange={commonOnChange}
                style={{ width: '120px' }}
                className="text-start"
              >
                <Option value="2021">2021</Option>
                <Option value="2022">2020</Option>
              </Select>
              <Select
                placeholder="Select Month"
                defaultValue={'March'}
                onChange={commonOnChange}
                style={{ width: '120px' }}
                className="text-start"
              >
                <Option value="January">January</Option>
                <Option value="February">February</Option>
                <Option value="March">March</Option>
              </Select>
            </Col>

          </Row>
        </Col>
        <Col span="24">
          <Table columns={ordersCols} dataSource={tableData} />
        </Col>
      </Row>
    </> :
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Invoice Detail
            </Col>
            <Col span="10" className="text-end">
            <Button type="primary" size="small" onClick={()=> setDetailObj(null)}>
                Back
              </Button>
              <Button type="default" size="medium" className="btn-success me-2">
                 <FaCloudDownloadAlt className="me-1" color="#777" /> Download Invoice
              </Button>
              { detailObj.amount_due === 0 ? 
            <Tag color="green">{ detailObj.payment_status}</Tag>
            :   <Button className="order-now-btn" 
                         onClick={()=> { LoadPage("checkout", dispatch);
                          sessionStorage.setItem("checkout",JSON.stringify({ ...detailObj , co_type : "invoice" }) );      }}>
                          Pay { detailObj.amount_due/100}
                         </Button>  }   
            </Col>           
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24 mb-2">
          <Row span="40">
            <Col span="11">
              <ul className="list-unstyled full-width customer-details-list">
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Company Name:</span>
                    <span className="each-customer-details">
                      IBEE Solutions
                    </span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Customer Name:</span>
                    <span className="each-customer-details">{cust ? cust.display_name : null}</span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width text-truncate">
                    <span className="each-customer-title">Email:</span>
                    <span className="each-customer-details">
                      <Tooltip title="Verified">
                        <IoCheckmarkCircle
                          color="var(--bs-success)"
                          size="18"
                        />
                      </Tooltip>
                      {cust ? cust.email : null}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Phone:</span>
                    <span className="each-customer-details">
                      <Tooltip title="Not Verified">
                        <IoAlertCircle color="var(--bs-warning)" size="18" />
                      </Tooltip>
                      {""}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">GST:</span>
                    <span className="each-customer-details"> {"To Do" }</span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">
                      KYC Verification:
                    </span>
                    <span className="each-customer-details">
                      <Tooltip title="Verified">
                        <IoCheckmarkCircle
                          color="var(--bs-success)"
                          size="18"
                        />
                      </Tooltip>{' '}
                      {"To Do"}
                    </span>
                  </div>
                </li>
              </ul>
            </Col>

            <Col span="9" offset="4">
              <ul className="list-unstyled full-width customer-details-list">
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Invoice Nos.:</span>
                    <span className="each-customer-details">{ detailObj._id }</span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Invoice Date:</span>
                    <span className="each-customer-details">
                      {detailObj.order_date}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Payment Status</span>
                    <span className="each-customer-details">
                      <Tag color="red">{detailObj.payment_status}</Tag>
                    </span>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
        <Col span="24">
          {/* <Table
            columns={ordersCols}
            dataSource={tableData}
            pagination={false}
          /> */}
          <div className="ant-table">
            <div className="ant-table-container">
              <div className="ant-table-content" style={{ fontSize: '14px' }}>
                <table
                  className="w-100 table-striped"
                  style={{ tableLayout: 'auto' }}
                >
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell">Item Description</th>
                      <th className="ant-table-cell" width="70">
                        Qty.
                      </th>
                      <th className="ant-table-cell" width="100">
                        Item Price/Unit
                      </th>
                      <th className="ant-table-cell" width="100">
                        Discount
                      </th>
                      <th className="ant-table-cell text-end" width="120">
                        Total Tax
                      </th>
                      <th className="ant-table-cell text-end" width="130">
                        Sub Total
                      </th>
                    </tr>
                  </thead>
                  <tbody className="ant-table-tbody">
                    <tr>
                      <td className="ant-table-cell">
                        <strong>1. E5-2430 v3/DDR5/16GB/SSD/960GB</strong>
                      </td>
                      <td className="ant-table-cell">1</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 12500
                      </td>
                      <td className="ant-table-cell">0</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 2500
                      </td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 14750
                      </td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell ps-3">
                        Microsoft Windows Standard 2Lic CoreLic VL
                      </td>
                      <td className="ant-table-cell">1</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 0
                      </td>
                      <td className="ant-table-cell">0</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 0
                      </td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 0
                      </td>
                      <td className="ant-table-cell"></td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell ps-3">
                        Microsoft SQL Server Web Edition 2 Lic Core Lic VL
                      </td>
                      <td className="ant-table-cell">1</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 0
                      </td>
                      <td className="ant-table-cell">0</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 0
                      </td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 0
                      </td>
                      <td className="ant-table-cell"></td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell">
                        <strong>2. CloudServer/DDR5/16GB/SSD/960GB</strong>
                      </td>
                      <td className="ant-table-cell">1</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 12500
                      </td>
                      <td className="ant-table-cell">0</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 2500
                      </td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 14750
                      </td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell ps-3">
                        Microsoft SQL Server Web Edition 2 Lic Core Lic VL
                      </td>
                      <td className="ant-table-cell">1</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 0
                      </td>
                      <td className="ant-table-cell">0</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 0
                      </td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 0
                      </td>
                      <td className="ant-table-cell"></td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell">
                        <strong>3. ObjectStorage/960GB</strong>
                      </td>
                      <td className="ant-table-cell">1</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 12500
                      </td>
                      <td className="ant-table-cell">0</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 2500
                      </td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 14750
                      </td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell text-end"></td>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell text-end">SubTotal:</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 14750
                      </td>
                      <td className="ant-table-cell"></td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell text-end"></td>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell text-end">Taxes:</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 14750
                      </td>
                      <td className="ant-table-cell"></td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell text-end"></td>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell text-end">Discount:</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 147
                      </td>
                      <td className="ant-table-cell"></td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell text-end"></td>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell text-end">
                        <strong>Grand Total:</strong>:
                      </td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> {detailObj.amount}
                      </td>
                      <td className="ant-table-cell"></td>
                    </tr>
                    <tr>
                      <td colSpan="7" className="text-end">
                      { detailObj.amount_due === 0 ? 
            <Tag color="green">{ detailObj.payment_status}</Tag>
            :   <Button className="order-now-btn" 
                         onClick={()=>  {
                          LoadPage("checkout", dispatch);
                          sessionStorage.setItem("checkout",JSON.stringify({ ...detailObj , co_type : "invoice" }) );
                         } }>
                          Pay { detailObj.amount_due/100}
                         </Button>  }   
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Col>
        <Col span="24" className="mt-2">
            <div className="full-width mb-2"><strong>Offline payment Bank details</strong></div>
            <div className="full-width">
              Account Name: IBEE SOFTWARE SOLUTIONS PRIVATE LIMITED <br/>
Account No.: 020205001561<br/>
Bank Name: ICICI Bank<br/>
IFSC Code: ICIC0000202<br/>
Branch: Punjagutta, Hyderabad<br/>
            </div>
        </Col>
      </Row>
    </>
  );
};

export default Invoices;