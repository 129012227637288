import React, {useState, useContext, useEffect} from "react";
import { Row, Col, Input, Card ,  Button, Breadcrumb, Divider, Radio } from "antd";

import { ImSortAlphaAsc } from "react-icons/im";

// Custom imports
import {Context} from "../../context";
import { BREADCRUMB } from "../../reducer/types";
import { LoadPage } from "../../SharedFunctions";
import { POST , apiURL , GET , PUT } from "../../common/crud" ;


const DedicatedServersManage = () => {

  const {state, dispatch} = useContext(Context);
  const [cartOrders,setCartOrders] = useState([]);
  const [activeObj,setActiveObj] = useState(null);
  const { Search } = Input;

  const onSearch = (value) => console.log(value);

  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button type="link" className="p-0" onClick={() => LoadPage("/", dispatch)}>
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        Dedicated Server - <em>Manage</em>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  const getCartItems = () => {

    GET("orders",state.token).then((res)=>{
        console.log(res.data);
        setCartOrders(res.data);
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });

  }

  useEffect(() => {

    getCartItems();
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav
      }
    });


    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    }

  }, []);


   return (
      activeObj === null ?
    <>
     <Row>
       <Col span="24" className="full-width page-header">
         <Row align="middle">
           <Col span="14" className="page-title">
             Dedicated Servers - <span>Manage</span>
           </Col>
           <Col span="10" flex="row" align="end">
             <Row>
               <Col span="8">
                <Button type="default">
                  <ImSortAlphaAsc style={{marginRight: '8px', color: "#666"}} /> Sort by Name
                </Button>
               </Col>
               <Col span="1">&nbsp;</Col>
               <Col span="15">
                 <Search
                   placeholder="Search..."
                   onSearch={onSearch}
                   style={{ width: "100%" }}
                 />
               </Col>
             </Row>
           </Col>
         </Row>
         
         <Row align="stretch">
            <Col span={17}>
            <div className="full-width order-list-block">
              <ul className="order-list list-unstyled full-width mb-0">
              { cartOrders.map((obj,index)=>{
                return obj.payment_status === "paid" ?        
                <li>
                  <div className="each-server-order full-width">
                  <div className="each-server-info">
                    <div className="each-server-title full-width">
                      Dell R220 / Intel Xeon E3-1231 v3 / 32 GB RAM / 2x2 TB
                      SATA
                    </div>
                    <div className="each-server-desc full-width">
                      { obj.products.map((pro)=>{
                        return  "cart-"+obj._id+" pro-" + pro.product_id + JSON.stringify(pro.product_info)
                      })}
                    </div>
                    <div className="each-server-provisioning full-width">
                      24 hours provisioning
                    </div>
                  </div>
                  <div className="each-server-pricing-block">
                    <div className="full-width from-text text-left">from</div>
                    <div className="full-width server-price">
                        {obj.amount/100}                      
                      <span className="server-per-month"> / Month</span>
                    </div>
                    <div className="full-width currency-converter-block">
                      <span className="converter-text">USD => EUR</span>
                    </div>
                  </div>
                    <div className="order-block"> 
                        <Button className="order-now-btn" 
                         onClick={() => { setActiveObj(obj) } }>
                          Details
                         </Button>
                    </div> 
                  </div>
                </li> : null })}
               </ul>
              </div>
              </Col>
            </Row>
       </Col>
     </Row> </>:
      <>
        <Card title={<Row> <Col span={16}>Details</Col>
        <Col span={8}> <Button onClick={()=>setActiveObj(null)}>Back</Button>
        <Button>Rename</Button> <Button>set Group</Button> </Col></Row>}>
        <Row > <Col span={4}>Name:</Col> <Col span={8}> </Col>      </Row>
        <Row > <Col span={4}>ID</Col> <Col span={8}>{activeObj._id} </Col>      </Row>
        <Row > <Col span={4}>CPU</Col> <Col span={8}> </Col>      </Row>
        <Row > <Col span={4}>OS</Col> <Col span={8}> </Col>      </Row>
        <Row > <Col span={4}>Location</Col> <Col span={8}> </Col>      </Row>
        <Row > <Col span={4}>Chasis</Col> <Col span={8}> </Col>      </Row>

        <Row > <Col span={4}>Ram size</Col> <Col span={8}> </Col>      </Row>
        <Row > <Col span={4}>HDDs info</Col> <Col span={8}> </Col>      </Row>
        </Card>
        
        <Card title={<Row> <Col span={16}>IP Addresses</Col>
        <Col span={8}> <Button>Refresh</Button> <Button>PTR Records</Button>
        <Button>Add Network</Button>  </Col></Row>}>
           <Row><Col span={4}>public/private</Col> <Col span={4}>CIDR</Col>
           <Col span={4}>Gateway</Col>
           <Col span={4}>Type</Col>
           <Col span={4}>Status</Col>           
           </Row>
        </Card>
        
        <Card title={<Row> <Col span={16}>Power Management</Col>
        <Col span={8}> <Button>Refresh</Button>  </Col></Row>}>
          Power Status     powered on
          <Radio>reboot</Radio>
          <Radio>off</Radio>
        </Card>
      </>
   );
}


export default DedicatedServersManage;