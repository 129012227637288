import React, { useState, useEffect, useContext } from "react";

import {
  Row,Divider,notification,
  Col,
  Button,
  Breadcrumb,
  Modal,
  Table,
  Tooltip,
  message,Layout
} from "antd";
import { ImGift } from "react-icons/im";
import { BiRupee, BiEditAlt, BiTrashAlt } from "react-icons/bi";
import { Formik, Field, Form } from "formik";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./stripeckForm.jsx";

// Custom imports
import "./cart.scss";
import { Context } from "../../context";
import { BREADCRUMB } from "../../reducer/types";
import { LoadPage } from "../../SharedFunctions";
import { DELETE, POST , apiURL , GET , PUT } from "../../common/crud" ;


// Table Dummy Content

const stripePromise = loadStripe("pk_test_JSmI8CYxBq8b1rkIPE5I1cRH00fNmam7qb");

const  CheckOut = (props) => {
   const { Header, Footer, Sider, Content } = Layout;
   const [cust,setCust] = useState(JSON.parse(sessionStorage.getItem("userData")));
   const [detail,setDetail] = useState(JSON.parse(sessionStorage.getItem("checkout")));
   const [paymode,setPaymode] = useState(null);
   const [clientSecret, setClientSecret] = useState("");
   const { state, dispatch } = useContext(Context);
   const [payInfo,setPayInfo] =  useState(null);
   const [elem,setElem] = useState(null);
  

  const startPayment = (order_id,gateway) => {

    POST(`invoices/${order_id}/${gateway}`,state.token,{}).then((res)=>{
      console.log(res);  
      if(res.success === true && res.rpay_id ){
            triggerRazorPayment(res.invoice_id, res.rpay_id );
            setPayInfo(res);
        }
      if(res.success === true && res.stripe_id){
        let cs =   res.stripe_client_secret;
        setClientSecret(cs);setPayInfo(res);
        }
    });
  }

  const postCaptuePayment = (inv_id,str_id , razorBody) => {
    POST(`invoices/${inv_id}/${str_id}/charge`, state.token, razorBody)
    .then((res) => {
      if(res.success){
        message.success("payment succes");
        console.log("payment success " , res)
        //LoadPage("dsManage", dispatch);
      }
      else{
        message.open("unknown error");
      }
    });

  }


   const triggerRazorPayment = (inv_id ,rpay_id) =>{
    const rzrpy = new window.Razorpay({
      key: "rzp_test_aOgG0SwmN2IsYl",
      name: "Ibee Portal",
      order_id: rpay_id ,
      handler: function (response) {
        const razorBody = { paymentId: response.razorpay_payment_id,
          signature: response.razorpay_signature, gateway : "razorpay",
        };
          console.log(response);
        postCaptuePayment(inv_id,rpay_id,razorBody);
        /* alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature); */
      },
      prefill: {
        name: state.userId,
        email: cust !== null && cust !== undefined ? cust.email : "",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#011d11",
      },
    });
    rzrpy.open();
  }

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };



   return ( <>
   <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title">
              Checkout  <span></span>
            </Col>
            <Col span="10" flex="row" align="end">
              {/*<Button type="default" onClick={showModal}>
                <ImGift style={{ marginRight: "10px" }} />
                Apply Promocode
              </Button>*/}
            </Col>
          </Row>
        </Col>
      </Row>
        <Row align="stretch">
        <Col span="24" className="p-2">
          <Row>
                <Col span={6}>
                     Server Details
                </Col>
                <Col span={6}>
                    Order Id
                </Col>
                <Col span={6}>
                    Invoice id
                </Col>
                <Col span={6}>
                    Payment
                 </Col>                
            
          </Row>
          <Divider/>
          <Row>
            
              { detail.co_type === "invoice" ?
                <>
                <Col span={6}>
                     Server Details
                </Col>
                <Col span={6}>
                    {detail.order_id}
                </Col>
                <Col span={6}>
                    {detail._id}
                </Col>
                <Col span={6}>
                <Button onClick={()=>triggerRazorPayment(detail._id,detail.razorpay_id)}>
                    pay {detail.amount_due}
                </Button> </Col>                
                </>:
                detail.co_type === "cart_order" ?
                <>
                <Col span={6}>
                     Server Details
                </Col>
                <Col span={6}>
                    {detail._id}
                </Col>
                <Col span={6}>
                    {detail.invoice_id}
                </Col>
                <Col span={6}>
            
                <Button onClick={()=>triggerRazorPayment(detail.invoice_id,detail.razorpay_id)}>
                    Pay { detail.payment_status === "partial" ? detail.amount_due/100 :
                  detail.payment_status === "pending" ? detail.cart_price : 0 }   
                </Button></Col> </> : null
              }            
          </Row>
          <Row> 
            
          {clientSecret && payInfo && (
            <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm  postCaptuePayment={postCaptuePayment}  
                  inv_id = { payInfo.invoice_id} order_id={payInfo.order_id}
                  stripe_id = {payInfo.stripe_id} />
            </Elements>
          )}
          </Row>
          <Row> 
            {
              detail.co_type === "cart_order" && detail.order_status !== "cart_abandoned" ?
                <><Button onClick={()=> triggerRazorPayment(detail._id,detail.razorpay_id  )}>
                    Pay { detail.payment_status === "partial" ? detail.amount_due/100 :
                        detail.payment_status === "pending" ? detail.cart_price : 0 }   
                </Button></> :
                         detail.co_type === "invoice" ?
                         <Button onClick={()=>triggerRazorPayment(detail._id, detail.razorpay_id )}>
                             pay {detail.amount_due}
                          </Button> :              
              detail.co_type === "cart_order" && detail.order_status === "cart_abandoned" ?
              <><Button onClick={()=> startPayment(detail._id , "razorpay") }>
              Start payment with razorpay 
          </Button>
          <Button onClick={()=> { startPayment(detail._id , "stripe") } }>
              Start payment with stripe 
          </Button>
           </> :
            null }


          </Row>
          <Row>
            <Col span="24" className="">
                {/*JSON.stringify(detail)*/}
              <div className="full-width font-weight-bold fs-7">
                Each server includes:
              </div>
              <div className="full-width pt-1 fs-7">
                <ul className="">
                  <li>Instant setup, including OS install</li>
                  <li>
                    Self-service panel (Control Power, KVM, OS reinstall,
                    Billing and more)
                  </li>
                  <li>24/7 customer support</li>
                  <li>Instant Hardware Replacement</li>
                  <li>Power management (on/off/reboot)</li>
                  <li>Physical console access (KVM)</li>
                  <li>10 days money back guarantee — no questions asked</li>
                </ul>
              </div>
              <div className="full-width mt-2 fs-7">
                Note, that after the order is submitted your configuration can't
                be modified. Please make sure to pay for the order within 30
                days. Please also note that orders not being paid within 30 days
                will be deleted.
              </div>
            </Col>
          </Row>
          {/*<Row className="mt-2 border-top pt-2">
            <Col span="12">
               <Button type="default">
                  Clear Cart
               </Button>
            </Col>
            <Col span="12" className="text-right" style={{textAlign: "right"}}>
               <Button type="default" className="">Continue Shopping</Button>
               <Button type="default" className="btn-primary ms-15">Buy Now</Button>
            </Col>
                    </Row>*/}
        </Col>
      </Row></>
   );
  };

  export default CheckOut;