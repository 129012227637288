import React, {useState, useEffect, useContext} from 'react';

import {
  Row,
  Col,
  Button,
  Typography,
  Radio,
  Space,
  Breadcrumb,
  Slider,
  Modal,
  Table,
  Tag,
  message,Divider
} from "antd";
import { ImGift } from "react-icons/im"
import {BiRupee} from "react-icons/bi";
import { Formik, Field, Form } from "formik";

// Custom imports
import "./dedicatedServerOrder.scss";
import {Context} from "../../context";
import { BREADCRUMB } from "../../reducer/types";
import { LoadPage } from '../../SharedFunctions';
import { DELETE, POST , apiURL , GET , PUT } from "../../common/crud" ;

// Dummy for testing
const ramJ = ["32", "64", "128", "256", "512", "1024"];
const coresJ = ["2", "4", "8", "16", "32", "64"];



const DedicatedServersOrder = () => {
  const [filterOption, setFilterOption] = useState("all");
  const [cust,setCust] = useState( JSON.parse(sessionStorage.getItem("userData"))  );
  const [products,setProducts] = useState([]);
  const [cartOrders,setCartOrders] = useState([]);
  const [addons,setAddons] = useState([]);
  //Modal
  const [isModalVisible, setIsModalVisible] = useState(false);


  const { Title } = Typography;

  const { state, dispatch } = useContext(Context);


  //RAM Slider Steps
  const ramSteps = {
      1: "32",
      2: "64",
      3: "128",
      4: "256",
      5: "512",
      6: "1024"
  }


  // Tooltip Formater
  const ramTooltip = (e) => {
    console.log("formater - ", e);
    const gbRam = ramJ.map((ramNumb, ind) => {
      if (e === ind + 1) {
        console.log("format values -", ramNumb);
        return ramNumb;
      }
    });

    const abc = `${gbRam}`;

    return abc.replaceAll(",", "");
  }

  //Cores Slider Steps
  const coresSteps = {
    1: "2",
    2: "4",
    3: "8",
    4: "16",

  };

  const coresTooltip = (e) => {
    const corJVal = coresJ.map((cor, ind) => {
      if(e === ind+1) {
        return cor;
      }
    });

    const corVal = `${corJVal}`;

    return corVal.replaceAll(',', "");
  }

  //Price Marks
  const priceMarks = {
    20000: "20,000",
    520000: "5,20,000"
  };

  //Price Range Before Change
  const priceBefore = () => {
    console.log('Price Before Change');
  }


  //Price Range After change
  const priceAfter = () => {
    console.log("Price After Change");
  };



  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setFilterOption(e.target.value);
  };

  // Promocode Popup
  const showModal = () => {
    setIsModalVisible(true);
  };

  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage("/", dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        Dedicated Server - <em>Order</em>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  const goToServerConfig = () => {
    LoadPage("serverConfiguration", dispatch);
  }

  const closePormo = () => {
    setIsModalVisible(false);
  }

  const getProducts = () =>{
    GET("products",state.token).then((res)=>{
      console.log(res.data);  
      setProducts(res.data ? res.data.products : []);
      sessionStorage.setItem("products" , JSON.stringify(res.data.products)  );
    }).catch(err=>{  
      setProducts([]);
    })    
  }

  const getAddons = () =>
  {
    GET("add-ons",state.token).then((res)=>{
      console.log(res.data);  
      setAddons(res.data ? res.data.addons : []);
      sessionStorage.setItem("addons" , JSON.stringify(res.data.addons)  );
    }).catch(err=>{  
      setAddons([]);
    })    
  }
    
  
  useEffect(() => {
    getProducts();
    getAddons();
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {};
  }, []);

  return (
    <>
      {/* Page Header */}
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title">
              Dedicated Servers - <span>Order</span>
            </Col>
            <Col span="10" flex="row" align="end">
              <Button type="default" onClick={showModal}>
                <ImGift style={{ marginRight: "10px" }} />
                Apply Promocode
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      
          {/*<Row align="stretch">
            <Col span={17}>
            <div className="full-width order-list-block">
              <ul className="order-list list-unstyled full-width mb-0">
              { cartOrders.map((obj,index)=>{
                return obj.payment_status !== "paid" ?        
                <li>
                  <div className="each-server-order full-width">
                  <div className="each-server-info">
                    <div className="each-server-title full-width">
                      Dell R220 / Intel Xeon E3-1231 v3 / 32 GB RAM / 2x2 TB
                      SATA
                    </div>
                    <div className="each-server-desc full-width">
                      { obj.products.map((pro)=>{
                        return  "cart-"+obj._id+" pro-" + pro.product_id + JSON.stringify(pro.product_info)
                             })}
                    </div>
                    <div className="each-server-provisioning full-width">
                      24 hours provisioning
                    </div>
                  </div>
                  <div className="each-server-pricing-block">
                    <div className="full-width from-text text-left">from</div>
                    <div className="full-width server-price">
                        {obj.amount/100}                      
                      <span className="server-per-month"> / Month</span>
                    </div>
                    <div className="full-width currency-converter-block">
                      <span className="converter-text">USD => EUR</span>
                    </div>
                  </div>
                    <div className="order-block"> 
                        <Button className="order-now-btn" 
                         onClick={()=>  triggerPayment(obj) }>
                          Pay due {obj.amount_due/100}
                         </Button>
                    </div> 
                    <Button className="order-now-btn" 
                         onClick={()=>  deletecartOrder(obj._id) }>
                          Delete Order
                         </Button>
                  </div>
                </li> : null })}
               </ul>
              </div>
              </Col>
            </Row>*/}
            <Divider></Divider>
      {/* Page Content */}
      <Row align="stretch">
        <Col span="17">
          <div className="full-width order-list-block">
            <ul className="order-list list-unstyled full-width mb-0">
            <li>
                <Button
                  type="link"
                  className="each-server-order full-width"
                  onClick={() => goToServerConfig()}
                >
                  <div className="each-server-info">
                    <div className="each-server-title full-width">
                      Dell R220 / Intel Xeon E3-1231 v3 / 32 GB RAM / 2x2 TB
                      SATA
                    </div>
                    <div className="each-server-desc full-width">
                      Intel Xeon E3-1231 v3 32 GB DDR3 RAM (up to 32GB) 2 x 2 TB
                      SATA 3.5" Location: DFW2 (Dallas–Fort Worth, TX, USA)
                    </div>
                    <div className="each-server-provisioning full-width">
                      24 hours provisioning
                    </div>
                  </div>
                  <div className="each-server-pricing-block">
                    <div className="full-width from-text text-left">from</div>
                    <div className="full-width server-price">
                      <span className="server-price-number">
                        <BiRupee />
                        99,999.00
                      </span>
                      <span className="server-per-month"> / Month</span>
                    </div>
                    <div className="full-width currency-converter-block">
                      <span className="converter-text">USD => EUR</span>
                    </div>
                  </div>
                  <div className="order-block">
                    <span className="order-now-btn">Order Now</span>
                  </div>
                </Button>
              </li>
              
              { 
              products.map((pro,index) => {
              return pro.category === "baremetal" ?
               <li>
                  <Button
                  type="link"
                  className="each-server-order full-width"
                  onClick={() => { goToServerConfig();  sessionStorage.setItem("activeProduct",JSON.stringify(pro)); } }
                >
                  <div className="each-server-info">
                    <div className="each-server-title full-width">
                      {pro.title}
                      {/* JSON.stringify(pro.chasis_info)*/}
                    </div>
                    <div className="each-server-desc full-width">
                      {pro.description} / Location: { pro.location}
                    </div>
                    <div className="each-server-provisioning full-width">
                      24 hours provisioning
                    </div>
                  </div>
                  <div className="each-server-pricing-block">
                    <div className="full-width">
                      <div className="full-width from-text text-left">from</div>
                      <div className="full-width server-price">
                        <span className="server-price-number">
                          <BiRupee />
                            {pro.product_price}
                        </span>
                        <span className="server-per-month"> / Month</span>
                      </div>
                      <div className="full-width currency-converter-block">
                        <span className="converter-text">USD => EUR</span>
                      </div>
                    </div>
                  </div>
                  <div className="order-block">
                    <Button className="order-now-btn"
                    onClick={()=>{}}>Order Now</Button>
                  </div>
                </Button>
              </li> : null }) }
            </ul>
          </div>
        </Col>
        <Col span="7" style={{ borderLeft: "1px solid #eee" }}>
          <div className="full-width">
            <Title className="p-2" level={5}>
              Filters
            </Title>
            <div className="full-width p-2 pt-0 border-bottom">
              <Radio.Group onChange={onChange} value={filterOption}>
                <Space direction="horizontal">
                  <Radio value={"all"}>
                    <BiRupee />
                  </Radio>
                  <Radio value={1}>$</Radio>
                </Space>
              </Radio.Group>
            </div>
            <div className="full-width p-2 border-bottom">
              <div className="ful-width pb-1">Price Range</div>
              <div className="full-width px-1">
                <Slider
                  range
                  min={20000}
                  max={520000}
                  step={10}
                  defaultValue={[20000, 520000]}
                  onChange={priceBefore}
                  onAfterChange={priceAfter}
                  marks={priceMarks}
                  className="customSlider"
                />
              </div>
            </div>
            <div className="full-width p-2 border-bottom">
              <div className="ful-width pb-1">Location</div>
              <div className="full-width">
                <Radio.Group onChange={onChange} value={"hyderabad"}>
                  <Space direction="vertical">
                    <Radio value={"hyderabad"}>Hyderabad</Radio>
                    <Radio value={11} disabled={true}>
                      USA
                    </Radio>
                  </Space>
                </Radio.Group>
              </div>
            </div>
            <div className="full-width p-2 border-bottom">
              <div className="ful-width pb-1">Max RAM (in GB)</div>
              <div className="full-width">
                <Slider
                  marks={ramSteps}
                  dots={true}
                  className="customSlider"
                  step={1}
                  min={1}
                  max={ramJ.length}
                  tipFormatter={ramTooltip}
                  defaultValue={2}
                />
              </div>
            </div>
            <div className="full-width p-2 border-bottom">
              <div className="ful-width pb-1">No. of Cores in Processor</div>
              <div className="full-width">
                <Slider
                  marks={coresSteps}
                  tipFormatter={coresTooltip}
                  step={1}
                  min={1}
                  max={4}
                  defaultValue={2}
                  className="customSlider"
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Modal title="Apply promo code" visible={isModalVisible} footer={null}>
        <Row>
          <Col span="24">
            <Formik
              initialValues={{
                promoCode: "",
              }}
              onSubmit={async (values) => {
                await new Promise((r) => setTimeout(r, 500));
                alert(JSON.stringify(values, null, 2));
              }}
            >
              <Form>
                <ul className="form-list list-unstyled full-width mb-0">
                  <li>
                    <div className="each-form-item full-width">
                      <label htmlFor="firstName" className="form-label">
                        Promo code
                      </label>
                      <Field
                        id="firstName"
                        name="promoCode"
                        placeholder="Enter Promocode"
                        className="form-control"
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className="each-form-item mt-2 pt-2 border-top full-width actions-btns"
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        type="default"
                        className="mx-15"
                        onClick={() => closePormo()}
                      >
                        Cancel
                      </Button>
                      <Button type="primary" className="btn-primary ml-1">
                        Apply
                      </Button>
                    </div>
                  </li>
                </ul>
              </Form>
            </Formik>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default DedicatedServersOrder;
