import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Menu,
  Dropdown,
  Button,
  Breadcrumb,
  Card,
  Radio,
  Tooltip,
  Tabs,
  Checkbox,
  Form,
  Select,
  Switch,
  DatePicker,
  Table,
  Modal,
  Tag,
  message,
  notification,
} from 'antd';

import { IoAdd, IoPencil, IoTrash, IoInformationCircle } from 'react-icons/io5';
import { FaRupeeSign } from 'react-icons/fa';

//Custom imports
import { Context } from '../../context';
import { BREADCRUMB } from '../../reducer/types';
import { LoadPage, EditForm } from '../../SharedFunctions';
import { POST , apiURL , GET , PUT } from "../../common/crud" ;

const BillingOrders = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [cust,setCust] = useState( JSON.parse(sessionStorage.getItem("userData"))  );
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [chasisSKU, setChasisSKU] = useState('');
  const [chasisTableData, setChasisTableData] = useState(null);
  const [ramSKU, setRamSKU] = useState(null);
  const [hddSKU, setHddSKU] = useState(null);
  const [stopModal, setStopModal] = useState(false);

  const { state, dispatch } = useContext(Context);
  const { Option } = Select;
  const { TextArea } = Input;

  //Table Columns
  const ordersCols = [
    {
      title: 'Date',
      dataIndex: 'order_date',
      key: 'order_date',
      width: '125px',
    },
    {
      title: 'BillingOrders',
      dataIndex: '_id',
      key: '_id',
      render: (text, id) => (
        <Button type="link" className="p-0">
          {text}
        </Button>
      ),
    },
    {
      title: 'Last Invoice',
      dataIndex: 'last_invoice',
      key: 'last_invoice',
    },

    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      className: 'text-end',
      width: '150px',
      render: (e,record) => (
        <>
          
            <>
              <FaRupeeSign size="13" color="#777" />
              Pay { record.payment_status === "partial" ? record.amount_due/100 :
                record.payment_status === "pending" ? record.cart_price : 0 } 
            </>
          
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'order_status',
      key: 'order_status',
      width: 120,
      render: (text) => (
        <>
            <Tag color="orange">{text}</Tag>
          
        </>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'payment_status',
      key: 'payment_status',
      width: 120,
      render: (e,record,text) => (
        <>
          {record.payment_status === 'paid' ? (
            <Button type="default" size="small" onClick={() => setStopModal(record)} 
            className="w-100 text-center">Cancel</Button>
          ) : record.payment_status === 'not paid' ? (
            <>
              <Button type="default" size="small"  className="w-100 text-center" onClick={() => LoadPage('cart', dispatch)}>Pay Now</Button>
            </>
          ) : record.payment_status === 'pending' ? (
            <>
              <Button type="default" size="small" disabled  className="w-100 text-center">In Progress</Button>
            </>
          ) : record.payment_status === 'cancelled' ? (
            ''
          ) : ( record.payment_status   )}
        </>
      ),
    },
  ];

  //Table Data
  const processorTableData = [
    {
      key: 'id1',
      date: '14 Jul, 2021',
      orders: '#ORD123456',
      displayName: 'Company Nmae 1',
      customer: 'S Yugandhar',
      email: 'yugandhr.designer@gmail.com',
      phone: '+9112134456789',
      location: 'Hyderabad, India',
      category: 'Baremetal',
      productTitle: 'E5-2430 v3/DDR5/16GB/SSD/1TB',
      lastInvoice: '10 Mar 2021',
      amount: '22,500.00',
      paymentStatus: 'paid',
    },
    {
      key: 'id2',
      date: '10 Jul, 2021',
      orders: '#ORD58748',
      displayName: 'Company Nmae 2',
      customer: 'TATA',
      email: 'yugandhr@gmail.com',
      phone: '+9112134456789',
      location: 'Mumbai, India',
      category: 'ObjectSroage',
      productTitle: 'E5-2430 v3/DDR4/8GB/SSD/500GB',
      lastInvoice: '9 Mar 2021',
      amount: '15,800.00',
      paymentStatus: 'cancelled',
    },
    {
      key: 'id3',
      date: '24 Jun, 2021',
      orders: '#ORD548728',
      displayName: 'Company Name 3',
      customer: 'Accenture',
      email: 'designer@gmail.com',
      phone: '+9112134456789',
      location: 'USA',
      category: 'Firewall',
      productTitle: 'E5-2430 v3/DDR4/8GB/SSD/500GB',
      lastInvoice: '8 Mar 2021',
      amount: '18,500.00',
      paymentStatus: 'pending',
    },
    {
      key: 'id4',
      date: '14 Jun, 2021',
      orders: '#ORD58748',
      displayName: 'Company Nmae 2',
      customer: 'TATA',
      email: 'shanker.yguandhar@gmail.com',
      phone: '+9112134456789',
      location: 'Mumbai, India',
      category: 'ObjectSroage',
      productTitle: 'E5-2430 v3/DDR4/8GB/SSD/500GB',
      lastInvoice: '9 Mar 2021',
      amount: '15,800.00',
      paymentStatus: 'not paid',
    },
    {
      key: 'id5',
      date: '04 Jun, 2021',
      orders: '#ORD548728',
      displayName: 'Company Name 3',
      customer: 'Accenture',
      email: 'shanker.yguandhar@gmail.com',
      phone: '+9112134456789',
      location: 'USA',
      category: 'Firewall',
      productTitle: 'E5-2430 v3/DDR4/8GB/SSD/500GB',
      lastInvoice: '8 Mar 2021',
      amount: '18,500.00',
      paymentStatus: 'not paid',
    },
  ];

  const getListOrders = () =>{
    GET(`orders`,state.token).then((res) =>{ 
      console.log(res.data);
      setTableData(res.data);
    });
  }

  const cancelOrders = (inv_id) => {
    POST(`invoices/${inv_id}/cancel`,state.token , {}).then((res)=>{
          console.log(res);
          setStopModal(false);
          message.open(res.data);
    });

  }


  //Show Order Details
  const goToOrderDetail = (id) => {
    LoadPage('invoiceDetail', dispatch);
  };

  //Show Customer Detail
  const goToCustomerDetail = (id) => {
    LoadPage('customerDetails', dispatch);
  };

  //Row Data on Edit
  const tableRowEdit = (key) => {
    console.log('Got Row ID - ', key);
    //EditForm(key, "addLocation", dispatch);
    setUpdateModal(true);
  };

  //Common onChange
  const commonOnChange = () => {};

  //Confirm Modal Delete
  const deleteItem = (id) => {
    setDeleteModal(true);
  };

  //Row Data on Delete
  const tableRowDelete = (key) => {
    console.log('Got Row Id, DELETE - ', key);

    //For Demo only Check & Delete
    const updatedTable =
      tableData !== null && tableData.length > 1
        ? tableData.splice(key, 1)
        : [];

    setTableData(updatedTable);
  };

  //Row Selection
  const rowSelection = {
    onChange: (e) => {
      console.log('Row selection on change - ', e);
    },
  };

  //Cancel Delete Modal
  const cancelModal = () => {
    setDeleteModal(false);
  };

  const showModal = () => {
    setStopModal(true);
  };

  const handleCancel = () => {
    setStopModal(false);
  };

  //Chasis SKU on change
  const chasisSKUOnChange = (value) => {
    setChasisSKU(value);
  };

  //RAM SKU onChange
  const ramSKUOnChange = (value) => {
    setRamSKU(value);
  };

  // HDD SKu onChange
  const hddSKUOnChange = (value) => {
    setHddSKU(value);
  };

  //Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('/', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>Billing Orders</Breadcrumb.Item>
    </Breadcrumb>
  );


  //  Yes Stop Recurring
  const onStopRecurring = () => {
    setStopModal(false);
    notification.success({
      message: "Successfully your Order Number #ORD123456 stopped!",
    })
  }

  //  Cancel stop Recurring
  const cancelStopRecurring = () => {
    setStopModal(false);
  }

  useEffect(() => {
    //Bredcrumb
    getListOrders();
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    //set Table Data
    //setTableData(processorTableData);

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Billing Orders
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24" className="mb-1">
          <Row gutter="30">
            <Col>
              <Select
                placeholder="Select Location"
                defaultValue={'All Locations'}
                onChange={commonOnChange}
                style={{ width: '200px' }}
              >
                <Option value="All Locations">All Locations</Option>
                <Option value="Hyderabad,India">Hyderabad, India</Option>
                <Option value="Mumbai,India">Mumbai, India</Option>
              </Select>
            </Col>
            <Col>
              <Select
                placeholder="Select Status"
                defaultValue='All'
                onChange={commonOnChange}
                style={{ width: '200px' }}
              >
                <Option value="All">All Payment Status</Option>
                <Option value="paid">Paid</Option>
                <Option value="payment failed">Payment Failed</Option>
                <Option value="not_paid">Not Paid</Option>
                <Option value="pending">Pending</Option>
                <Option value="cancelled">Cancelled</Option>
              </Select>
            </Col>

          </Row>
        </Col>
        <Col span="24">
          <Table columns={ordersCols} dataSource={tableData} />
        </Col>
      </Row>

       <Modal title="Basic Modal" visible={stopModal} onCancel={handleCancel} okText="Yes Stop Recurring" 
       okButtonProps={{className: 'btn-danger', title: "Yes Stop Recurring"}} onOk={()=>cancelOrders(stopModal.invoice_id)}>
        <Row>
          <Col style={{fontSize: '20px'}}>
            Dear {cust ? cust.email : null}, are you sure, do you really want to <span className="text-danger"> 
            Stop Order {stopModal ? stopModal._id : null } from Next Month</span>.  Once stopped, this order cannot be revoked, you need to again create new order!
          </Col>
        </Row>
        <Row>
        </Row>
      </Modal>

    </>
  );
};

export default BillingOrders;