import React, { useState, useEffect, useContext } from "react";

import {
  Row,
  Col,
  Button,
  Breadcrumb,
  Modal,
  Table,
  Tooltip,
  message
} from "antd";
import { ImGift } from "react-icons/im";
import { BiRupee, BiEditAlt, BiTrashAlt } from "react-icons/bi";
import { Formik, Field, Form } from "formik";


// Custom imports
import "./cart.scss";
import { Context } from "../../context";
import { BREADCRUMB } from "../../reducer/types";
import { LoadPage } from "../../SharedFunctions";
import { DELETE, POST , apiURL , GET , PUT } from "../../common/crud" ;
// Table Dummy Content

const CartOrderDetails = (props) => {
  return (
    <div className="order-details-info">
      <div className="order-main-title full-width text-truncate">
        {props.mainTitle}
      </div>
      <div className="order-sub-title full-width text-truncate">
        {props.subTitle}
      </div>
      <div className="order-sub-sub-title full-width text-truncate">
        {props.subSubTitle}
      </div>
    </div>
  );
};

const CartPrice = (props) => {
   return (
      <div className="cart-price-block">
         <span className="cart-price-conversion">{props.priceConv}</span>
         <div className="cart-price">
            <span className="cart-price-number"><BiRupee /> {props.price}</span> <spna className="cart-price-month"> / {props.duration}</spna>
         </div>
      </div>
   )
}

const Amount = (props) => {
   return (
     <>
       <BiRupee /> {props.price}
     </>
   );
}




const columns = [
  {
    title: "Order Details",
    dataIndex: "order_details",
    className: "order-details-col w-40",
  },
  {
    title: "Price",
    dataIndex: "price",
    className: "cart-price w-20",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    className: "cart-quantity text-center w-10",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    className: "cart-amount text-right w-10",
  },
  {
    title: "Status",
    dataIndex: "status",
    className: "cart-price w-20",
  },
  {
    title: "",
    dataIndex: "actions",
    className: "cart-actions text-right w-10",
  },
];


const Cart = () => {
  //Modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cust,setCust] = useState( JSON.parse(sessionStorage.getItem("userData"))  );
  const [cartOrders,setCartOrders] = useState([]);
  const [data,setData] = useState([]);
  const { state, dispatch } = useContext(Context);

  const getCartItems = () => {
    GET("orders",state.token).then((res)=>{
        console.log(res.data);
        setCartOrders(res.data);
        let objData = res.data;
        let dat = [];
        objData.map((obj,index)=> {    
          if( obj.payment_status !== "paid")
          dat.push(
            {   key: index,   order_details: (
                <CartOrderDetails    mainTitle= { obj.products[0].product_id}
                  subTitle="Location: DFW2 (Dallas–Fort Worth, TX, USA)"
                  subSubTitle={ obj.products[0].category}
                />
            ),
            price : (
              <CartPrice priceConv="USD => INR" price={obj.products[0].price} duration="Month" />
            ),
            quantity :  obj.products[0].quantity ,
            amount : ( <>  <BiRupee />  { obj.cart_price}    </>     ),
            status : (<Button   onClick={()=>{
              LoadPage("checkout", dispatch);
              sessionStorage.setItem("checkout",JSON.stringify({ ...obj , co_type : "cart_order"}) );
            }}
            >
                Pay { obj.payment_status === "partial" ? obj.amount_due/100 :
                obj.payment_status === "pending" || obj.order_status === "cart_abandoned" ? obj.cart_price : 0 }     </Button>) ,
            actions: (
              <>            
                
                <Tooltip title="Edit">
                  <Button type="link" className="edit-btn">
                    <BiEditAlt />     </Button>
                </Tooltip>
                {/*<Tooltip title="Delete">
                  <Button type="link" className="delete-btn"
                  onClick={()=> deletecartOrder(obj._id)}>
                    <BiTrashAlt />
                  </Button>
                </Tooltip>*/}
              </>
            )
            , });  
            });

        setData(dat);
    
    }).catch((err) => {
      console.log('Get err - ', err);
    });
  }

  const deletecartOrder = (id) =>{
    DELETE(`orders`, id ,state.token).then((res)=>{
       message.open("Succesfully Deleted");
       getCartItems();
    })
  }


  const closePormo = () => {
    setIsModalVisible(false);
  };

  // Promocode Popup
  const showModal = () => {
    setIsModalVisible(true);
  };

  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage("/", dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Cart</Breadcrumb.Item>
    </Breadcrumb>
  );



  useEffect(() => {
    getCartItems();
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    

    return () => {};
  }, []);
  /*{
    key: "cartFooter",
    orderDetails: "",
    price: (
      <>
        <strong>Grand Total</strong>
      </>
    ),
    qty: "",
    amount: (
      <>
        <BiRupee /> 3,99,99,996.00
      </>
    ),
    actions: "",
  },*/

  return (
    <>
      {/* Page Header */}
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title">
              Cart - <span>Order</span>
            </Col>
            <Col span="10" flex="row" align="end">
              {/*<Button type="default" onClick={showModal}>
                <ImGift style={{ marginRight: "10px" }} />
                Apply Promocode
              </Button>*/}
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Page Content */}
      <Row align="stretch">
        <Col span="24" className="p-2">
          <Row>
            <Col span="24">
              <Table
                columns={columns}
                dataSource={ data}
                className="cart-table"
              />
            </Col>
          </Row>
          <Row>
            <Col span="24" className="">
              <div className="full-width font-weight-bold fs-7">
                Each server includes:
              </div>
              <div className="full-width pt-1 fs-7">
                <ul className="">
                  <li>Instant setup, including OS install</li>
                  <li>
                    Self-service panel (Control Power, KVM, OS reinstall,
                    Billing and more)
                  </li>
                  <li>24/7 customer support</li>
                  <li>Instant Hardware Replacement</li>
                  <li>Power management (on/off/reboot)</li>
                  <li>Physical console access (KVM)</li>
                  <li>10 days money back guarantee — no questions asked</li>
                </ul>
              </div>
              <div className="full-width mt-2 fs-7">
                Note, that after the order is submitted your configuration can't
                be modified. Please make sure to pay for the order within 30
                days. Please also note that orders not being paid within 30 days
                will be deleted.
              </div>
            </Col>
          </Row>
          {/*<Row className="mt-2 border-top pt-2">
            <Col span="12">
               <Button type="default">
                  Clear Cart
               </Button>
            </Col>
            <Col span="12" className="text-right" style={{textAlign: "right"}}>
               <Button type="default" className="">Continue Shopping</Button>
               <Button type="default" className="btn-primary ms-15">Buy Now</Button>
            </Col>
                    </Row>*/}
        </Col>
      </Row>
      <Modal title="Apply promo code" visible={isModalVisible} footer={null}>
        <Row>
          <Col span="24">
            <Formik
              initialValues={{
                promoCode: "",
              }}
              onSubmit={async (values) => {
                await new Promise((r) => setTimeout(r, 500));
                alert(JSON.stringify(values, null, 2));
              }}
            >
              <Form>
                <ul className="form-list list-unstyled full-width mb-0">
                  <li>
                    <div className="each-form-item full-width">
                      <label htmlFor="firstName" className="form-label">
                        Promo code
                      </label>
                      <Field
                        id="firstName"
                        name="promoCode"
                        placeholder="Enter Promocode"
                        className="form-control"
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className="each-form-item mt-2 pt-2 border-top full-width actions-btns"
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        type="default"
                        className="mx-15"
                        onClick={() => closePormo()}
                      >
                        Cancel
                      </Button>
                      <Button type="primary" className="btn-primary ml-1">
                        Apply
                      </Button>
                    </div>
                  </li>
                </ul>
              </Form>
            </Formik>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Cart;