const dev = true ;
const prod = process.env.NODE_ENV === 'production';
export const apiURL = dev ? 'https://dev-api.ibee.ai' : prod ? 'https://api.ibee.ai'  :'http://127.0.0.1:8002' ;

export const GET = (resource, authToken) => {
  console.log('GET got values - ', resource, authToken);
  const promise = new Promise((resolve, reject) => {
    fetch(`${apiURL}/${resource}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    }).then(async (res) => {
      if (res.status === 200) {
        const data = await res.json();
        resolve({ success: true, data });
      }
      throw res;
    }).catch((err) => {
      console.error(`Error fetching resource ${resource}:`, err.status);
      reject(err);
    });
  });
  return promise;
};

export const POST = (resource, authToken, payload) => {
  console.log('POST got values - ', resource, authToken, payload);
  const promise = new Promise((resolve, reject) => {
    fetch(`${apiURL}/${resource}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(payload),
    }).then(async (res) => {
       if (res.status === 201) {
        const data = await res.json();
        resolve(data);
      } else if (res.status === 422) {
        const data = await res.json();
        resolve({ success: false, errors: data.details });
      }
      throw res;
    }).catch((err) => {
      console.error(`Error creating resource ${resource}:`, err.status);
      reject(err);
    });
  });
  return promise;
};

export const PUT = (resource, id, authToken, payload) => {
  console.log('PUT got values - ', resource, id, authToken, payload);
  const promise = new Promise((resolve, reject) => {
    fetch(`${apiURL}/${resource}/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(payload),
    }).then(async (res) => {
      if (res.status === 200) {
        const data = await res.json();
        resolve(data);
      } else if (res.status === 422) {
        const data = await res.json();
        resolve({ success: false, errors: data.details });
      }

      throw res;
    }).catch((err) => {
      console.error(`Error updating resource ${resource} ${id}:`, err.status);
      reject(err);
    });
  });
  return promise;
};

export const DELETE = (resource, id, authToken) => {
  console.log('DELETE got values - ', resource, id, authToken);
  const promise = new Promise((resolve, reject) => {
    fetch(`${apiURL}/${resource}/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    }).then(async (res) => {
      if (res.status === 200) {
        const data = await res.json();
        resolve(data);
      }

      throw res;
    }).catch((err) => {
      console.error(`Error deleting resource ${resource} ${id}:`, err.status);
      reject(err);
    });
  });
  return promise;
};
