import React, { useState, useEffect, useContext } from "react";

import {
  Row,
  Col,
  Input,
  Menu,
  Dropdown,
  Button,
  Typography,
  Radio,
  Space,
  Breadcrumb,
  Slider,
  Modal,
  message,
} from "antd";
import { ImGift } from "react-icons/im";
import { BiRupee } from "react-icons/bi";
import {BsInfoCircleFill} from "react-icons/bs";
import { IoLogOutOutline, IoCart } from "react-icons/io5";
import { Formik, Field, Form } from "formik";

// Custom Imports
import "./serverConfiguration.scss";
import { LoadPage } from "../../SharedFunctions";
import { Context } from "../../context";
import { BREADCRUMB } from "../../reducer/types";
import { POST , apiURL , GET , PUT } from "../../common/crud" ;

const ServerConfiguration = () => {
  const [selectServer, setSelectServer] = useState("ser1");
  const [selectLocation, setSelectLocation] = useState("loc1");
  const [selectRAM, setSelectRAM] = useState(null);
  const [selectOS, setSelectOS] = useState(null);
  const [selectRaid, setSelectRaid] = useState("raid1");
  const [selectHDD, setSelectHDD] = useState(null);
  const [selectPubUplink, setSelectPubUplink] = useState("pubUl1");
  const [selectPrvUplink, setSelectPrvUplink] = useState("prvUl1");
  const [selectIpAddress, setSelectIpAddress] = useState("ip1");
  const [selectBandwidth, setSelectBandwidth] = useState("bw1");
  const [selectPriIp, setSelectPriIp] = useState("pIp1");
  const [selectSecIp, setSelectSecIp] = useState("sIp1");
  const [cust,setCust] = useState( JSON.parse(sessionStorage.getItem("userData")));
  const [activeProduct,setActiveProduct] = useState( JSON.parse(sessionStorage.getItem("activeProduct")));
  const [product,setProduct] = useState( JSON.parse(sessionStorage.getItem("products")));
  const [addons,setAaddons] = useState( JSON.parse(sessionStorage.getItem("addons")));
  const [localCart,setLocalCart] = useState( activeProduct ? activeProduct.product_price : 0);
  const [orderObj,setOrderobj] = useState(null);
  //Modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  const { state, dispatch } = useContext(Context);

  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage("/", dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage("dsOrder", dispatch)}
        >
          Dedicated Server
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Server Configuration</Breadcrumb.Item>
    </Breadcrumb>
  );

  const serverChange = (e) => {
    selectServer(e.target.value);
  };

  const locationChange = (e) => {
    setSelectLocation(e.target.value);
  };

  const computeLocalPrice = () =>{
    let prop = activeProduct ? activeProduct.product_price : 0;
    let os = JSON.parse(selectOS); let hdd = JSON.parse(selectHDD);let ram = JSON.parse(selectRAM);
    let osp = os ? os.price : 0 ;
    let ramp = ram ? ram.price : 0 ;
    let hddp = hdd ? hdd.price : 0 ;
    setLocalCart(prop+osp+ramp+hddp);
    console.log(localCart , prop , osp , ramp ,hddp);
  }

  useEffect(()=>{
      computeLocalPrice()
  },[selectOS,selectRAM,selectHDD])


  const ramChange = (e) => {
    setSelectRAM(e.target.value);
    computeLocalPrice();
    console.log(e.target.value);
    
  };

  const osChange = (e) => {
    setSelectOS(e.target.value);
    computeLocalPrice();
    //setLocalCart({ ...localCart , addons : e.target.value       });
    console.log(e.target.value);
  };

  const hddChange = (e) => {
    setSelectHDD(e.target.value);
    computeLocalPrice();
    //setLocalCart({ ...localCart , addons : e.target.value       });
    console.log(e.target.value);
  };

  const submitCart =  () => {
    let addonsTemp = [];
    let os = JSON.parse(selectOS); let hdd = JSON.parse(selectHDD);let ram = JSON.parse(selectRAM);
    if(os)
    addonsTemp.push( {
      "addon_id": os._id,  "category": "os",  "quantity": 1,  "price": os.price,  "addon_info": {}    } );
    if(ram)  
    addonsTemp.push( {
        "addon_id": ram._id,  "category": "ram",  "quantity": 1,  "price": ram.price,  "addon_info": {}    } );
    if(hdd)    
    addonsTemp.push( {
          "addon_id": hdd._id,  "category": "hdd",  "quantity": 1,  "price": hdd.price ,  "addon_info": {}    } );
    const cartBody = {
      customer_id : state.userId,
      "products": [
        {
          product_id: activeProduct._id,
          quantity: 1,
          category: "baremetal",
          "price": activeProduct.product_price,
          "product_info": {} ,
          addons : addonsTemp
        }
      ],
      discount_code: "No",
      cart_price: 0,
    }
    POST("invoices",state.token,cartBody).then((res)=>{
          setOrderobj(res.data);
          console.log(res);
          LoadPage("cart", dispatch);
    })
  }

  const closePormo = () => {
    setIsModalVisible(false);
  };

  //Show Promocode popup
  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    computeLocalPrice();
    return () => {};
  }, []);

  return (
    <>
      {/* Page Header */}
      <Row>
        <Col span="24" className="px-2 pt-2 pb-5 mb-2">
          <Row className="server-config-section-row mb-2">
            <Col span="16">
              <div className="full-width server-config-section">
                <div className="server-config-section-title full-width">
                  Choose Chasis:
                </div>
                <div className="server-config-options full-width">
                  <Radio.Group onChange={serverChange} value={selectServer}>
                    <Space direction="vertical">
                      <Radio
                        value={"ser1"}
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-server">
                          <div className="config-server-details">
                            <div className="config-server-title full-width text-truncate">
                              {activeProduct ? activeProduct.title : " product title goes here"}
                            </div>
                            <div className="config-server-info full-width text-truncate">
                            {activeProduct ? activeProduct.description : " product description goes here"}
                            </div>
                          </div>
                          <div className="config-price-block">
                            <div className="config-price-text">
                              <BiRupee /> { activeProduct ? activeProduct.product_price : "" } <span> / Month</span>
                            </div>
                            <div className="price-convertion">
                              <span>USD => EUR</span>
                            </div>
                          </div>
                        </div>
                      </Radio>
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </Col>
            <Col span="8"></Col>
          </Row>

          <Row className="server-config-section-row mb-2">
           {/* <Col span="16">
              <div className="full-width server-config-section">
                <div className="server-config-section-title full-width">
                  Choose the location:
                </div>
                <div className="server-config-options full-width">
                  <Radio.Group onChange={locationChange} value={selectLocation}>
                    <Space direction="vertical">
                      <Radio
                        value={"loc1"}
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-location">
                          <div className="config-location-title text-truncate">
                            AMS1 (Amazon Metropolitian Area, The Netherlands)
                          </div>
                          <div className="config-location-price">
                            <div className="config-price-text">
                              <BiRupee /> 9,99,999.00 <span> / Month</span>
                            </div>
                            <div className="price-convertion">
                              <span>USD => EUR</span>
                            </div>
                          </div>
                        </div>
                      </Radio>
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </Col>*/}
            <Col span="16">
              <div className="server-configur-helper-info">
                <BsInfoCircleFill /> You can always configure your server with
                additional RAM. Please note that changes in the standard
                configurations of RAM and HDD can delay server deployment for 1
                business day and options may vary depending on the Location
              </div>
            </Col>
          </Row>

          <Row className="server-config-section-row mb-2">
            <Col span="16">
              <div className="full-width server-config-section">
                <div className="server-config-section-title full-width">
                  Choose RAM-Size:
                </div>
                <div className="server-config-options full-width">
                  <Radio.Group onChange={ramChange} value={selectRAM}>                    
                    <Space direction="vertical">
                    {  addons.map((ao,index)=>
                    { return ao.category === "ram" ?
                      <Radio
                        value={ JSON.stringify(ao) }
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-location">
                          <div className="config-location-title text-truncate">
                            {ao.ramsize +" GB "+ao.technology}
                          </div>
                          <div className="config-location-price free">
                            {ao.price}
                          </div>
                          <div className="price-convertion">
                            <span>USD => EUR</span>
                          </div>
                        </div>
                      </Radio> : null })}
                      
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </Col>
            {/*<Col span="8">
              <div className="server-configur-helper-info">
                <BsInfoCircleFill /> You can always configure your server with
                additional RAM. Please note that changes in the standard
                configurations of RAM and HDD can delay server deployment for 1
                business day and options may vary depending on the Location
              </div>
            </Col>*/}
          </Row>
          <Row className="server-config-section-row mb-2">
            <Col span="16">
              <div className="full-width server-config-section">
                <div className="server-config-section-title full-width">
                  Choose Operating System:
                </div>
                <div className="server-config-options full-width">
                  <Radio.Group onChange={osChange} value={selectOS}>
                    <Space direction="vertical">
                    {  addons.map((ao,index)=>
                    { return ao.category === "os" ?
                     <Radio
                        value={ JSON.stringify(ao)}
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-location">
                          <div className="config-location-title text-truncate">
                            { ao.title +" " +ao.brand+" " +ao.ostype }
                          </div>
                          <div className="config-location-price free">
                            { ao.price === 0 ? "FREE of Charge" : ao.price }
                          </div>
                        </div>
                      </Radio> : null })}
                      
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </Col>
            <Col span="8"></Col>
          </Row>
          <Row className="server-config-section-row mb-2">
            <Col span="16">
              <div className="full-width server-config-section">
                <div className="server-config-section-title full-width">
                  Choose HDD:
                </div>
                <div className="server-config-options full-width">
                  <Radio.Group onChange={hddChange} value={selectHDD}>
                    <Space direction="vertical">
                    {  addons.map((ao,index)=>
                    { return ao.category === "hdd" ?
                     <Radio
                        value={ JSON.stringify(ao) }
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-location">
                          <div className="config-location-title text-truncate">
                            { ao.disksize +" " +ao.disktype+" " +ao.diskformfactor }
                          </div>
                          <div className="config-location-price free">
                            { ao.price === 0 ? "FREE of Charge" : ao.price }
                          </div>
                        </div>
                      </Radio> : null })}
                      
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </Col>
            <Col span="8"></Col>
          </Row>
        </Col>
      </Row>

      <div className="final-order-pricing-block">
        <div className="final-order-info">
          <div className="full-width order-info-title">
            Total Price:{" "}
            <span className="text-primary">
              <BiRupee /> {localCart}
            </span>{" "}
            <span className="text-info multiply">*</span> + FREE Setup
          </div>
          <div className="full-width order-misc-info">
            1 server will be deployed in 24 hours
          </div>
        </div>
        <div className="final-order-actions-block d-flex align-items-center justify-content-end">
          <Button
            type="default"
            className="d-inline-flex align-items-center"
            onClick={showModal}
          >
            <ImGift />
            &nbsp; Apply promo code
          </Button>
          <Button type="default">Cancel</Button>
          <Button type="default" className="btn-primary"
          onClick={()=> submitCart( )  }>
            <IoCart />
            &nbsp; Add to Cart
          </Button>
        </div>
      </div>

      <Modal title="Apply promo code" visible={isModalVisible} footer={null}>
        <Row>
          <Col span="24">
            <Formik
              initialValues={{
                promoCode: "",
              }}
              onSubmit={async (values) => {
                await new Promise((r) => setTimeout(r, 500));
                alert(JSON.stringify(values, null, 2));
              }}
            >
              <Form>
                <ul className="form-list list-unstyled full-width mb-0">
                  <li>
                    <div className="each-form-item full-width">
                      <label htmlFor="firstName" className="form-label">
                        Promo code
                      </label>
                      <Field
                        id="firstName"
                        name="promoCode"
                        placeholder="Enter Promocode"
                        className="form-control"
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className="each-form-item mt-2 pt-2 border-top full-width actions-btns"
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        type="default"
                        className="mx-15"
                        onClick={() => closePormo()}
                      >
                        Cancel
                      </Button>
                      <Button type="primary" className="btn-primary ml-1">
                        Apply
                      </Button>
                    </div>
                  </li>
                </ul>
              </Form>
            </Formik>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ServerConfiguration;
